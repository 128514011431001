<template>
  <div class="px-xl-16">
    <v-row class="mt-10 px-xl-16">
      <v-col cols="12" md="4">
        <BusinessProfile />
      </v-col>

      <v-col cols="12" md="8" class="mx-0 pr-10 mt-8" >
        <div class="pa-5">
          <v-row class="pa-0 mb-5">
            <v-col cols="12" lg="4" sm="6">
              <v-card  height="220" class="rounded-xl pa-5 bottom">
                <v-icon color="primary" class="my-1" large>
                  mdi-check-decagram
                </v-icon>
                <div>
                  We offer a choice of
                  <span class="sub-header">
                      accredited, compliance and personal development
                    </span>
                  courses.
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" lg="4" sm="6">
              <v-card  height="220" class="rounded-xl pa-5 bottom">
                <v-icon color="primary" class="my-1" large>
                  mdi-comment-arrow-right
                </v-icon>
                <div class="mb-3">
                  <div class="sub-header">Get in touch.</div>
                  Book in an initial meeting to identify your training needs.
                </div>

                <ContactUsButton  />
              </v-card>
            </v-col>

            <v-col cols="12" lg="4" sm="6">
              <v-card height="220" class="rounded-xl pa-5">
                <v-icon color="primary" class="my-1" large>
                  mdi-clock-time-two
                </v-icon>
                <div>
                  We aim to get back to you by the
                  <span class="sub-header">
                      next working day.
                    </span>
                  <div class="mt-2">
                    Our staff support hours are:
                    <v-chip class="mr-2 mb-2" small> Monday to Friday </v-chip>

                    <v-chip class="mb-2" small>9am to 5pm.</v-chip>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <v-card
            v-for="(card, i) in cards"
            :key="i"
            class="rounded-xl mb-10 pa-10" flat
            :color="card.color"
        >
          <v-row class="pa-0">
            <v-col md="6" cols="12" class="ma-auto">
              <div class="card-subtitle">
                {{ card.text }}
              </div>
              <div class="pp-title-bs secondary--text">
                {{ card.title }}
              </div>
              <div class="pp-subtitle-bs">
                {{ card.subtitle }}
              </div>
            </v-col>
            <v-col md="6" sm="12" class="pa-0">
              <v-img :src="card.img" width="100%"
                     class="rounded-xl pa-0 ma-0" contain  />
            </v-col>
          </v-row>
        </v-card>

        <div>
          <span class="sub-header primary--text">Success Journey with LearningPlus</span>

          <v-card class="rounded-xl mt-2">
            <v-img width="100%" src="/images/business/timeline-01.jpg" />
          </v-card>

          <v-card class="rounded-xl mt-10 business-price pa-5 white--text">
            <div class="sub-header white--text">Cost for Training Providers</div>

            <v-row class="pa-7">
              <v-col cols="12" sm="6" class=" outline">
                <span class="card-subtitle white--text my-5">Platform Access and Usage:</span>
                <ul>
                  <li>Access and Use of the LearningPlus Learner Management Portal and System including data analytics, support and MO.</li>
                  <li>12 Month access price quoted per organisation/site headcount.</li>
                </ul>
              </v-col>
            </v-row>

            <v-row class="pa-7">
              <v-col cols="12" sm="6" class=" outline">
                <span class="card-subtitle white--text my-5">Course Prices:</span>
                <ul>
                  <li>Bespoke course and rate card to suit your requirements.</li>
                  <li>Buy in bulk or as you use licences monthly</li>
                  <li>Volume usages and packages available</li>
                </ul>
              </v-col>
            </v-row>
          </v-card>

          <div class="pa-5 mt-6">
            <span class="sub-header primary--text">What's next?</span>

            <v-row class="pa-0 my-3">
              <v-col cols="12" lg="4" sm="6">
                <v-card  height="220" class="rounded-xl pa-5 bottom">
                  <v-icon color="primary" class="my-1" large>
                    mdi-comment-arrow-right
                  </v-icon>
                  <div class="mb-3">
                    <div class="sub-header">Get in touch.</div>
                    Book in an initial meeting to identify your training needs.
                  </div>

                  <ContactUsButton />
                </v-card>
              </v-col>

              <v-col cols="12" lg="4" sm="6">
                <v-card class="launch-button pp-scale-1" :href="portal" target="_blank">
                  <v-icon color="white" class="my-1 pt-8" large>
                    mdi-cast-education
                  </v-icon>
                  <div class="sub-header white--text">
                    Launch <br />
                    LearningPlus <br />
                    Portal
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" lg="4" sm="6">
                <v-card class="launch-button pp-scale-1" :href="pps" target="_blank">
                  <v-icon color="white" class="my-1 pt-8" large>
                    mdi-cast-education
                  </v-icon>
                  <div class="sub-header white--text">
                    Launch <br />
                    PeoplePlus <br />
                    System (PPS)
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BusinessProfile from "@/components/BusinessProfile";
import ContactUsButton from "@/components/buttons/ContactUsButton";


export default {
  name: "BusinessLanding",
  components: {ContactUsButton, BusinessProfile},
  data: () => ({
    portal: process.env.VUE_APP_LEARNER_PORTAL,
    pps: process.env.VUE_APP_PPS,
    cards: [
      {
        color: 'transparent',
        text: 'Friendly user experience',
        title: 'Netflix style approach.',
        subtitle: 'Aggregation of content from multiple sources, all procured with you, the training provider in mind!',
        img: '/images/business/portal-04.png',
      },
      {
        color: 'white',
        text: 'Leading digital learning solution',
        title: 'Built and managed by training and recruitment professionals.',
        subtitle: 'Designed to enhance the skills and working capability of newly recruited candidates.',
        img: '/images/business/portal-02.png',
      },
      {
        color: 'transparent',
        text: 'All in one place',
        title: 'The only platform that delivers competency training.',
        subtitle: 'LearningPlus Portal and fundable qualifications are used on a daily basis by employers as part of Sector Based Work Academies.',
        img: '/images/business/portal-04.jpg',
      },
      {
        color: 'white',
        text: 'Ready when you are',
        title: 'Visit our online shop for immediate course requirements.',
        subtitle: 'Come on board as a Managed Client benefiting from dedicated account management and packages built for; and with you!',
        img: '/images/business/portal-01.jpg',
      },
      {
        color: 'transparent',
        text: 'Platform of choice',
        title: 'Chosen by the largest recruitment agency in the UK.',
        subtitle: 'Enabling us to advise and work with employers to advise training providers what courses they would most like to see delivered as part of their Sector Based Work Academies.',
        img: '/images/business/portal-05.png',
      },
    ],
  }),
};
</script>

<style scoped>
.launch-button {
  background-image: url("/images/categories/pp-default.jpg");
  background-size: cover;
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  height: 220px;
}

.launch-button:hover {
  background-image:    linear-gradient(to bottom, rgba(103, 67, 67, 0), rgb(146, 1, 1)),
  url("/images/categories/pp-default.jpg");
}

.outline {
  outline: white 2px solid;
  padding: 15px;
  border-radius: 18px;
}

.business-price {
  background-image: url("/images/customer/learner-2.jpg");
  background-size: cover;
  background-position: center;
}
.bottom {
  position: relative;
  bottom: 0;
}

.sub-header {
  font-size: 22px;
  line-height: 1.14286;
  font-weight: 600;
  letter-spacing: .007em;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  color: #1d1d1f;
  margin-top: 0;
}

.card-subtitle {
  letter-spacing: 1px;
  text-transform: uppercase;
  font: 700 12px/1 Axiforma,Arial,serif;
  margin-bottom: 20px;
}

.pp-title-bs {
  font: 800 30px/38px Axiforma,Arial,serif;
  color: #b11116;
  margin: 20px 0 40px;
}

.pp-subtitle-bs {
  font-weight: 500;
  font-size: large;
  margin: 20px 0;
}
</style>
