<template>
  <v-card class="mx-md-5 my-12 ">
    <v-img contain src="/images/shades/business-banner.jpg" />

    <div>
      <div class="pa-5">
        <v-toolbar flat>
          <v-icon color="primary" class="mr-2">mdi-account</v-icon>
          <v-toolbar-title class="font-weight-light">
            Your Business Account
          </v-toolbar-title>
        </v-toolbar>
        <Loading title="Loading your profile" :loading="userProfileLoading" />
        <ValidationObserver
            v-slot="{ handleSubmit, invalid }">
          <v-form :disabled="userProfileLoading">
            <ValidationProvider name="GivenName" rules="required|name"
                                v-slot="{ errors, valid }">
              <v-text-field
                  outlined
                  prepend-inner-icon="mdi-file-account-outline"
                  placeholder="First Name"
                  name="given_name"
                  label="First Name"
                  :error-messages="errors"
                  :success="valid"
                  :disabled="!isAuth0Profile"
                  v-model="given_name"
                  aria-required="true"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider name="FamilyName" rules="required|name"
                                v-slot="{ errors, valid }">
              <v-text-field
                  outlined
                  placeholder="Family Name"
                  name="family_name"
                  label="Family Name"
                  :error-messages="errors"
                  :success="valid"
                  :disabled="!isAuth0Profile"
                  v-model="family_name"
                  aria-required="true"
              ></v-text-field>
            </ValidationProvider>

            <ValidationProvider name="ContactNumber" rules="required|ukTel"
                                v-slot="{ errors, valid }">
              <v-text-field
                  outlined
                  prepend-inner-icon="mdi-card-account-phone"
                  placeholder="Contact Number"
                  name="contact_number"
                  label="Contact Number"
                  :error-messages="errors"
                  :success="valid"
                  v-model="contact_number"
                  aria-required="true"
              ></v-text-field>
            </ValidationProvider>
            <div class="mb-6">
              <ValidationProvider name="BusinessName" rules="required" v-slot="{ errors, valid }">
                <v-text-field
                    outlined
                    prepend-inner-icon="mdi-account-box-outline"
                    placeholder="Business Name"
                    v-model="business_name"
                    name="business_name"
                    label="Business Name"
                    :error-messages="errors"
                    :success="valid"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="BusinessIndustry" rules="required" v-slot="{ errors, valid }">
                <v-text-field
                    outlined
                    prepend-inner-icon="mdi-briefcase"
                    placeholder="Business Industry"
                    v-model="business_industry"
                    name="business_industry"
                    label="Business Industry"
                    :error-messages="errors"
                    :success="valid"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="NumberOfEmployees" rules="required" v-slot="{ errors, valid }">
                Number of employees/learners
                <v-radio-group v-model="number_of_employees"
                               :error-messages="errors"
                               :success="valid">
                  <v-radio v-for="employees in numberOfEmployees"
                           :key="employees"
                           :label="employees"
                           :value="employees"/>
                </v-radio-group>
              </ValidationProvider>
              <ValidationProvider name="BusinessLocation" rules="required" v-slot="{ errors, valid }">
                <div class="mt-3">
                  Is your business operating in multiple locations?
                </div>
                <v-radio-group v-model="business_location"
                               :error-messages="errors"
                               :success="valid">
                  <v-radio label="Yes" value="Yes"></v-radio>
                  <v-radio label="No" value="No"></v-radio>
                </v-radio-group>
              </ValidationProvider>
              <ValidationProvider name="BusinessSectors" v-slot="{ valid }">
                <div class="my-4">
                  Which sector(s) would you be interested in?
                </div>
                <v-checkbox v-for="sector in businessSectors"
                            v-model="business_sectors"
                            :key="sector"
                            :success="valid"
                            :label="sector"
                            :value="sector"
                            multiple
                            dense
                />
              </ValidationProvider>
              <ValidationProvider name="BusinessSectorOther"
                                  :rules="`${ business_sectors.length == 0 ? 'required' : ''}`"
                                  v-slot="{ errors, valid }">
                <v-checkbox :success="valid"
                            label="Other"
                            value="Other"
                            dense
                            class="mb-0 pb-0"
                />
                <v-text-field v-model="business_sector_other"
                              label="Please State"
                              :error-messages="errors"
                              :success="valid"
                              regular
                              dense
                              placeholder="Sector Name(s)"
                              class="mt-0 ml-8"
                >
                </v-text-field>

              </ValidationProvider>
              <ValidationProvider name="BusinessVolume" rules="required|numeric|min:1" v-slot="{ errors, valid }">
                <div class="mt-5 pb-4">
                  Volume of Courses Required
                </div>
                <v-text-field v-model="business_volume"
                              name="business_volume"
                              outlined
                              prepend-inner-icon="mdi-play-box-multiple"
                              type="number"
                              placeholder="Volume of courses"
                              :error-messages="errors"
                              :success="valid"
                              label="Volume of courses required"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="PurchaseDate" rules="required" v-slot="{ errors, valid }">
                <div class="my-5">
                  When do you plan to purchase the courses?
                </div>
                <v-row class="ma-0 pa-0">
                  <v-date-picker
                      class="rounded-xl elevation-1"
                      :error-messages="errors"
                      :success="valid"
                      width="100%"
                      v-model="purchase_date"
                      label="When do you plan to purchase the courses?"
                      min="2022-01-01"
                      max="2100-01-01"
                  >
                  </v-date-picker>
                </v-row>
              </ValidationProvider>

              <ValidationProvider name="CourseAmount" rules="required" v-slot="{ errors, valid }">
                <div class="mt-10">
                  How many employees / learners are you looking to allocate courses for?
                </div>

                <v-radio-group v-model="course_amount"
                               :error-messages="errors"
                               :success="valid"
                >
                  <v-radio v-for="amount in courseAmounts"
                           :key="amount"
                           :label="amount"
                           :value="amount"
                  />
                </v-radio-group>
              </ValidationProvider>

              <ValidationProvider name="Frequency" rules="required" v-slot="{ errors, valid }">
                <div class="mt-3">
                  How often do they need to take those courses?
                </div>

                <v-radio-group v-model="frequency"
                               :error-messages="errors"
                               :success="valid">
                  <v-radio v-for="frequency in frequencies"
                           :key="frequency"
                           :label="frequency"
                           :value="frequency"
                  />
                </v-radio-group>
              </ValidationProvider>
            </div>
            <v-card outlined class="rounded-xl my-5">
              <v-img
                  src="/images/shades/marketing.jpg"
                  contain
              />
              <div class="pa-5">
                <p class="pp-title text-capitalize">Don't miss out on life changing opportunities!</p>
                <p class="font-weight-medium">
                  From time to time we’d like to contact you about some of the exciting courses, jobs and
                  opportunities that we have available. If you’d like to hear from us about these potentially
                  life-changing opportunities, select how you would like to be contacted:
                </p>

                <v-switch v-model="marketing"
                          dense inset color="primary"
                          label="Email" value="email"/>

                <v-switch v-model="marketing"
                          dense inset color="primary"
                          label="Phone calls" value="phone_call"/>

                <v-switch v-model="marketing"
                          dense inset color="primary"
                          label="Text" value="text"/>
              </div>
            </v-card>
            <div class="text-right">
              <v-btn
                  @click="handleSubmit(onSubmit)"
                  :disabled="invalid"
                  class="rounded-xl pp-scale-1 pa-6"
                  color="primary"
                  :loading="userProfileLoading"
              >
                Save Changes
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>
      <v-sheet
          v-model="dialog"
          hide-overlay
          persistent
          width="100%"
          color="transparent"
          class="mt-5"
      >
        <Loading title="Loading your profile"
                 :loading="userProfileLoading" />

        <v-snackbar v-if="!userProfileLoading" v-model="snackbar" class="sbar">
          Thank you, your profile has been updated.
        </v-snackbar>
      </v-sheet>
    </div>

  </v-card>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, numeric, min } from 'vee-validate/dist/rules';
import {mapActions, mapGetters} from "vuex";
import Loading from "@/components/common/Loading";
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('numeric', numeric);
extend('min', min);
extend('name', {
  validate: (value) => {
    const pattern = /^[\p{L} ,.'-]+$/u;
    return pattern.test(value);
  },
  message: 'Must be a valid name format',
});
extend('ukTel', {
  validate: (value) => {
    const pattern = /(\s*\(?0\d{4}\)?(\s*|-)\d{3}(\s*|-)\d{3}\s*)|(\s*\(?0\d{3}\)?(\s*|-)\d{3}(\s*|-)\d{4}\s*)|(\s*(7|8)(\d{7}|\d{3}(-|\s{1})\d{4})\s*)/;
    return pattern.test(value);
  },
  message: 'Must be a valid UK tel number',
});

export default {
  name: "BusinessProfile",
  components: {Loading, ValidationProvider, ValidationObserver},
  computed: {
    ...mapGetters(['editableUserProfile', 'profileMetadata', 'userProfileLoading', 'isAuth0Profile']),

    // Local computed vars that talk to vuex as that's how the validation works
    business_sectors: {
      get() {
        return this.profileMetadata.business_sectors;
      },
      set(val) {
        this.updateProfileMetadata({
          business_sectors: val,
        });
      },
    },
    business_name: {
      get() {
        return this.profileMetadata.business_name;
      },
      set(val) {
        this.updateProfileMetadata( {
          business_name: val,
        });
      },
    },
    business_sector_other: {
      get() {
        return this.profileMetadata.business_sector_other;
      },
      set(val) {
        this.updateProfileMetadata({
          business_sector_other: val,
        });
      },
    },
    business_industry: {
      get() {
        return this.profileMetadata.business_industry;
      },
      set(val) {
        this.updateProfileMetadata({
          business_industry: val,
        });
      },
    },
    business_location: {
      get() {
        return this.profileMetadata.business_location;
      },
      set(val) {
        this.updateProfileMetadata({
          business_location: val,
        });
      },
    },
    business_volume: {
      get() {
        return this.profileMetadata.business_volume;
      },
      set(val) {
        this.updateProfileMetadata({
          business_volume: val,
        });
      },
    },
    course_amount: {
      get() {
        return this.profileMetadata.course_amount;
      },
      set(val) {
        this.updateProfileMetadata({
          course_amount: val,
        });
      },
    },
    contact_number: {
      get() {
        return this.profileMetadata.contact_number;
      },
      set(val) {
        this.updateProfileMetadata({
          contact_number: val,
        });
      },
    },
    family_name: {
      get() {
        return this.editableUserProfile.family_name;
      },
      set(val) {
        this.updateProfile({
          family_name: val,
        });
      },
    },
    frequency: {
      get() {
        return this.profileMetadata.frequency;
      },
      set(val) {
        this.updateProfileMetadata({
          frequency: val,
        });
      },
    },
    given_name: {
      get() {
        return this.editableUserProfile.given_name;
      },
      set(val) {
        this.updateProfile({
          given_name: val,
        });
      },
    },
    number_of_employees: {
      get() {
        return this.profileMetadata.number_of_employees;
      },
      set(val) {
        this.updateProfileMetadata({
          number_of_employees: val,
        });
      },
    },
    marketing: {
      get() {
        return this.profileMetadata.marketing;
      },
      set(val) {
        this.updateProfileMetadata({
          marketing: val,
        });
      },
    },
    purchase_date: {
      get() {
        return this.profileMetadata.purchase_date;
      },
      set(val) {
        this.updateProfileMetadata({
          purchase_date: val,
        });
      },
    },
  },
  data: () => ({
    snackbar: false,
    dialog: false,
    businessSectors: [
      'Catering and Hospitality',
      'Construction',
      'Employability',
      'Food Safety',
      'Functional skills',
      'General Workplace Skills',
      'Health and Well-being',
      'Health and Safety',
      'Health and Social Care',
      'Retail',
      'Security',
      'Special Education Needs or Disability (SEND)',
      'Warehouse',
    ],
    courseAmounts: [
      '1-3 courses',
      '4-7 courses',
      '8-15 courses',
      '16+ courses',
    ],
    frequencies: [
      'Monthly',
      'Quarterly',
      'Biannually',
      'Annually',
      'When necessary or needed',
    ],
    numberOfEmployees: [
      'Less than 10',
      '11-50',
      '50-100',
      'More than 100',
    ],
  }),
  methods: {
    ...mapActions([
      'putUserProfile',
      'updateProfile',
      'updateProfileMetadata',
    ]),
    onSubmit() {
      this.putUserProfile();
      this.dialog = true;
      this.snackbar = true;
    },
  },
};
</script>

<style>
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  padding-right: 10px !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none !important;
  border: solid 0.05rem #d3d3d3;
}
.v-date-picker-table .v-btn.v-btn--active {
  color: green !important;
  transform: scale(1.5) !important;
}
.v-input--checkbox.v-input--dense {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.sbar {
  position: unset !important;
  height: 50px !important;
}
</style>
